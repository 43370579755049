<template>
  <div class="callback">Logging in...</div>
</template>

<script>
export default {
  name: 'AuthCallback',
  mounted() {
    this.$auth.handleAuthentication().then(
      () => {
        this.$bus.$emit('auth-change')
        this.$router.replace('/calc')
      },
      (err) => {
        // console.log('err...')
        // console.log(err)
        // console.log('route params...')
        // console.log(this.$route.params)
        // TODO: pass the error object into the error page...
        this.$router.replace({
          name: 'auth-error',
          params: {
            error: err.error,
            errorDescription: err.errorDescription,
            errorObj: err,
          },
        })
      }
    )
  },
}
</script>

<style></style>
