<template>
  <div class="auth-error">
    <b-container>
      <b-row>
        <b-col md="3" sm="2"></b-col>
        <b-col>
          <b-card
            id="auth-error-card"
            :header="messageHeader"
            border-variant="danger"
            header-text-variant="white"
            header-border-variant="danger"
            header-bg-variant="danger"
            align="center"
            header-class="font-weight-bold text-capitalize"
            class="my-5"
          >
            <p>There was a problem logging in.</p>
            <div v-if="errorDescription">
              <p>
                The message from the authentication server was: <br />
                <span class="font-weight-bold">{{ errorDescription }}</span>
              </p>
              <hr />
            </div>
            <p>
              This usually isn't because you got your password wrong. Check your
              email to see if your spam filter accidently flagged anything from
              Australian Earthing Apps, and make sure you've verified your
              account by clicking the link in the welcome email.
            </p>
            <hr />

            You can also try to
            <b-button variant="outline-primary" @click="login">Log In</b-button>
            again.
          </b-card>
        </b-col>
        <b-col md="3" sm="2"></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AuthError',
  props: {
    // return {
    error: {
      default: null,
      type: String,
    },
    errorDescription: {
      default: null,
      type: String,
    },
    errorObj: {
      default: null,
      type: Object,
    },
    // }
  },
  computed: {
    messageHeader() {
      var msg = 'Auth. Error'
      if (this.error) {
        msg += ': ' + this.error
      }
      return msg
    },
  },
  methods: {
    login: function () {
      // this.$auth.logout()
      //   .then(this.$auth.login())
      // TODO: figure out how to force the username / password entry to show up...
      this.$auth.forceLogin()
    },
  },
}
</script>

<style></style>
